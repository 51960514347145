import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { ReactComponent as CloudIcon } from "bootstrap-icons/icons/x-square-fill.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import handleUpdateService from "../Update/Update-Service/UpdateService";
import UpdateServiceofSchedules from "../../utils/Update/UpdateServiceofSchedules/UpdateServiceofSchedules";

function EditService({ service, onClose }) {
  const [formData, setFormData] = useState({
    name: service ? service.name : "",
    district: service ? service.district : "",
    province: service ? service.province : "",
    description: service ? service.description : "",
    WhatsApp: service ? service.WhatsApp : "",
    Facebook: service ? service.Facebook : "",
    imageUrl: service ? service.imageUrl : "",
  });
  const [loading, setLoading] = useState(false); // Estado para manejar la carga
  const [serviceSchedules, setServiceSchedules] = useState([]);
  const [shouldRefreshPage, setShouldRefreshPage] = useState(false);
  const [error, setError] = useState(null); // Estado para manejar errores

  // Estado para manejar el modal de edición de horarios
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (service && service.service_schedules) {
      setServiceSchedules(service.service_schedules);
    }
  }, [service]);

  const daysOptions = [
    { value: "Lunes", label: "Lunes" },
    { value: "Martes", label: "Martes" },
    { value: "Miércoles", label: "Miércoles" },
    { value: "Jueves", label: "Jueves" },
    { value: "Viernes", label: "Viernes" },
    { value: "Sábado", label: "Sábado" },
    { value: "Domingo", label: "Domingo" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setFormData({ ...formData, imageUrl: reader.result });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDaysStartChange = (index, e) => {
    const updatedSchedules = [...serviceSchedules];
    updatedSchedules[index].start_day = e.target.value;
    setServiceSchedules(updatedSchedules);
  };

  const handleDaysEndChange = (index, e) => {
    const updatedSchedules = [...serviceSchedules];
    updatedSchedules[index].end_day = e.target.value;
    setServiceSchedules(updatedSchedules);
  };

  const handleTimeChange = (index, field, e) => {
    const updatedSchedules = [...serviceSchedules];
    updatedSchedules[index][field] = e.target.value;
    setServiceSchedules(updatedSchedules);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Inicia la carga
    try {
      if (service) {
        await handleUpdateService(service.id, formData);
      } else {
        // Lógica para manejar la creación de servicio si es necesario
      }
      window.location.reload();
      setShouldRefreshPage(true);
      onClose(); // Cerrar modal
    } catch (error) {
      console.error("Error al guardar cambios:", error.message);

      setError("No se pudo guardar los cambios. Por favor, revisa los datos.");

      if (error.response) {
        console.error("Detalles del error:", error.response.data);
      }
    } finally {
      setLoading(false); // Finaliza la carga
    }
  };

  const handleSchedulesSubmit = async () => {
    try {
      console.log("Datos a enviar:", serviceSchedules);
      await Promise.all(
        serviceSchedules.map((schedule) =>
          UpdateServiceofSchedules(service.id, {
            id: schedule.id,
            start_day: schedule.start_day,
            end_day: schedule.end_day,
            start_time: schedule.start_time.slice(0, 5), // Corta para obtener "HH:mm"
            end_time: schedule.end_time.slice(0, 5), // Corta para obtener "HH:mm"
          })
        )
      );
      setShowModal(false);
    } catch (error) {
      console.error("Error al actualizar los horarios:", error);
      if (error.response) {
        console.error("Detalles del error:", error.response.data);
        setError(
          `No se pudo guardar los cambios en los horarios: ${
            error.response.data.message || "Error desconocido"
          }`
        );
      } else {
        setError(
          "No se pudo guardar los cambios en los horarios. Por favor, revisa los datos."
        );
      }
    }
  };

  useEffect(() => {
    if (shouldRefreshPage) {
      window.location.reload();
    }
  }, [shouldRefreshPage]);

  return (
    <div className="modal-service">
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block", overflowY: "auto" }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header bg-primary text-white d-flex justify-content-between">
              <h5 className="modal-title">Editar: {formData.name}</h5>
              <div style={{ display: "flex" }}>
                <Button
                  variant="primary"
                  onClick={() => setShowModal(true)}
                  style={{ marginRight: "10px" }}
                >
                  Editar Horario
                </Button>
                <CloudIcon
                  width="40"
                  height="40"
                  color="white"
                  onClick={onClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <div
                  className="row"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="form-group">
                      <label htmlFor="upload-photo">
                        <img
                          src={formData.imageUrl}
                          alt=""
                          style={{
                            borderRadius: "50%",
                            cursor: "pointer",
                            width: "150px",
                            height: "150px",
                          }}
                        />
                      </label>
                      <input
                        type="file"
                        id="upload-photo"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Nombre del servicio</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Ingrese el nombre"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="district">Distrito</label>
                      <input
                        type="text"
                        className="form-control"
                        id="district"
                        name="district"
                        placeholder="Ingresa el distrito"
                        value={formData.district}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="province">Provincia</label>
                      <input
                        type="text"
                        className="form-control"
                        id="province"
                        name="province"
                        placeholder="Ingresa la provincia"
                        value={formData.province}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="description">Descripción</label>
                      <input
                        type="text"
                        className="form-control"
                        id="description"
                        name="description"
                        placeholder="Ingresa la descripción"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="whatsapp">N° de Whatsapp</label>
                      <input
                        type="text"
                        className="form-control"
                        id="whatsapp"
                        name="WhatsApp"
                        placeholder="Ingresa el número de Whatsapp"
                        value={formData.WhatsApp}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="facebook">Página de Facebook</label>
                      <input
                        type="text"
                        className="form-control"
                        id="facebook"
                        name="Facebook"
                        placeholder="Ingresa la página de Facebook"
                        value={formData.Facebook}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {error && <div className="alert alert-danger">{error}</div>}

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Cerrar
                </button>

                {loading ? (
                  <Spinner animation="border" variant="primary" role="status">
                    <span className="sr-only">Guardando...</span>
                  </Spinner>
                ) : (
                  <Button variant="primary" type="submit">
                    Guardar cambios
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modal para editar horarios */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Horarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Renderización de los horarios */}
          {serviceSchedules.map((schedule, index) => (
            <div className="col-md-12" key={index}>
              <div className="row" style={{flexWrap:"wrap"}}>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Día de inicio</label>
                    <select
                      className="form-control"
                      value={schedule.start_day}
                      onChange={(e) => handleDaysStartChange(index, e)}
                    >
                      {daysOptions.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Día de fin</label>
                    <select
                      className="form-control"
                      value={schedule.end_day}
                      onChange={(e) => handleDaysEndChange(index, e)}
                    >
                      {daysOptions.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Horario</label>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="time"
                          className="form-control"
                          value={schedule.start_time}
                          onChange={(e) =>
                            handleTimeChange(index, "start_time", e)
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="time"
                          className="form-control"
                          value={schedule.end_time}
                          onChange={(e) =>
                            handleTimeChange(index, "end_time", e)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSchedulesSubmit}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditService;
