import axios from 'axios';
import { API_CONST_URL } from '../../../../config/api/api';
import dataDecrypt from '../../Encryp/data-decrypt';

const createUser = async (userData) => {
  try {
    const response = await axios.post(`${API_CONST_URL}/register`, userData);
    return response.data;
  } catch (error) {
    throw error; // Lanza el error para manejarlo donde se llame a esta función
  }
};

export default createUser;
