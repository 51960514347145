import React, { useEffect, useState } from 'react';
import ContentHeader from '../ContentHeader/ContentHeader';
import "../content/Content.css";
import Card from './Card';
import CardUser from './CardUser'; 
import { fetchUserData } from '../utils/utils/ApiMeUtils';
import dataDecrypt from '../utils/Encryp/data-decrypt';
import { dataEncrpt } from '../utils/Encryp/data-encrypt';



const USER_ROLE_KEY = "+Z7=1c]\"BpK6*;q";

function Content() {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('TOKEN_KEY');
        const userDataResponse = await fetchUserData();
        let encryptedRole = localStorage.getItem(USER_ROLE_KEY);
        let role;
        if (encryptedRole) {
          role = dataDecrypt(encryptedRole);
        } else {
          role = userDataResponse.role;
          localStorage.setItem(USER_ROLE_KEY, dataEncrpt(role));
        }
        setUserRole(role);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
  
   <>
      {userRole === 'Proveedor' && <Card />}
      {userRole === 'Administrador' && <CardUser />}
    </>
  );
}

export default Content;
