import axios from 'axios';
import { API_CONST_URL } from '../../../../config/api/api';
import dataDecrypt from '../../Encryp/data-decrypt';
const TOKEN_KEY = "access_tokenL";

function deleteServiceOf(serviceId, serviceProvidedData) {
  const url = `${API_CONST_URL}/delete/provide/${serviceId}`;
  const token = localStorage.getItem(TOKEN_KEY);

  if (!token) {
    throw new Error('No token found');
  }

  const decryptedToken = dataDecrypt(token);
  return axios.delete(url, {
    headers: {
      'Authorization': `Bearer ${decryptedToken}`,
      'Content-Type': 'application/json',
    },
    data: serviceProvidedData, // Pasar el cuerpo JSON con los datos necesarios
  })
  .then(response => {
    console.log('Servicio ofrecido eliminado exitosamente:', response.data);
    return response.data;
  })
  .catch(error => {
    console.error('Error al eliminar el servicio ofrecido:', error);
    throw error; // Puedes manejar el error según tus necesidades
  });
}

export default deleteServiceOf;
