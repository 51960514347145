/* export const API_CONST_URL = 'http://127.0.0.1:8000/api/auth'
export const API_SERVICE = 'http://127.0.0.1:8000/api/services'
export const API_DEPERTAMENTS ='http://127.0.0.1:8000/api' */


export const API_CONST_URL = 'https://apiworkpe.work-pe.com/api/auth'
export const API_SERVICE = 'https://apiworkpe.work-pe.com/api/services'
export const API_DEPERTAMENTS ='https://apiworkpe.work-pe.com/api'







/* https://apiworkpe.work-pe.com*/