import React, { useState } from "react";
import "./login.css";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import handleLogin from "../auth/login/Login";
import { handleLoginGmail, checkUser } from "../auth/login/Gmail/LoginGmail";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";

import { ReactComponent as GoogleIcon } from "bootstrap-icons/icons/google.svg";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { Button, Modal, Spinner } from "react-bootstrap";
import ico from'../../assets/logo.png'
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [googleLoginError, setGoogleLoginError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    await handleLogin(email, password, navigate);
    setLoading(false);
  };

  const Gmaillogin = useGoogleLogin({
    
    onSuccess: async (response) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );

        const userData = {
          email: res.data.email,
          sub: res.data.sub,
        };

        await checkUser(userData.email, userData.sub, navigate);
      } catch (err) {
       /*  console.log(err); */
        setGoogleLoginError("Error al iniciar sesión con Google");
      }
    },
    onFailure: (error) => {
      /* console.log("Error en la autenticación con Google:", error); */
      setGoogleLoginError("Error al iniciar sesión con Google");
    },
  });

  const handleFacebookLogin = () => {
    // Lógica para iniciar sesión con Facebook
    // Aquí se implementaría la funcionalidad específica de inicio de sesión con Facebook
  };

  return (
    <div className="text-center section__login">
      {loading ? (
        <div className="spinner-container">
          <img src={ico} alt="" />
          <Spinner animation="border" variant="primary" role="status" ></Spinner>
        </div>
      ) : (
      <main className="form-signin w-100 m-auto">
        <form onSubmit={handleSubmit}>
          <img className="mb-4" src={Logo} alt="Logo" height={42} />
          <h1 className="h5 mb-3 fw-bold">Panel de Administración</h1>
          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="floatingInput">Correo</label>
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label className="label" htmlFor="floatingPassword">
              Contraseña
            </label>
          </div>
          <button className="w-100 btn btn-lg btn-primary" type="submit">
            Iniciar Sesión
          </button>

          <button className="google" onClick={Gmaillogin}>
            <span><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="30px"
              height="30px"
            >
              <path
                fill="#FFC107"
                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
              />
              <path
                fill="#FF3D00"
                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
              />
              <path
                fill="#4CAF50"
                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
              />
              <path
                fill="#1976D2"
                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
              />
            </svg></span>
            Iniciar sesió con Google
          </button>
          {googleLoginError && (
            <p className="text-danger">{googleLoginError}</p>
          )}

          {/* Botón de Iniciar sesión con Facebook */}
          {/*  <Button
              onClick={handleFacebookLogin}
              className="border"
            >
              <div className="fb">
                
              </div>
              <div>
                <span>Iniciar sesión con Facebook</span>
              </div>
            </Button> */}
        </form>
      </main>
      )}
    </div>
  );
};

export default Login;
