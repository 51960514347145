import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Content from "../../content/Content";
import ListService from "../../ListService/ListService";
import GestionService from "../../GestionService/GestionService";
import Profile from "../../Profile/Profile";
import Sidebar from "../../sidebars/sidebars";
import "../../../index.css";
import Card from "../../content/Card";
import HeaderSidebar from "../../sidebars/headerSidebar";
function RoutesHome() {
  return (
    <div className="dashboard">
      <Sidebar />
      <div className="content-header-dashboard">
        <HeaderSidebar />
        <div
          className="dashboard--content"
          style={{ height: "100%", overflow: "auto" }}
        >
          <Routes>
            <Route path="*" element={<Content />} />
            <Route path="/dashboard" element={<Card />} />
            <Route path="/Lista-de-Servicio" element={<ListService />} />
            <Route path="/Gestionar-Services" element={<GestionService />} />
            <Route path="/ProfileUser" element={<Profile />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default RoutesHome;
