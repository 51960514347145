import axios from 'axios';
import { API_CONST_URL } from '../../../config/api/api';
import dataDecrypt from '../Encryp/data-decrypt';

const TOKEN_KEY = "access_tokenL";

export const fetchUserData = async () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      throw new Error('No token found');
    }
    const decryptedToken = dataDecrypt(token);

    const response = await axios.post(`${API_CONST_URL}/me`, null, {
      headers: {
        Authorization: `Bearer ${decryptedToken}`,
      },
    });
    return response.data.user;
  } catch (error) {
    console.error('Error fetching user data', error);
    throw error;
  }
};
