import axios from 'axios';
import { API_CONST_URL } from '../../../../config/api/api';
import { dataEncrpt } from '../../../utils/Encryp/data-encrypt';

const TOKEN_KEY = 'access_tokenL';

export const checkUser = async (email, sub, navigate) => {
  /* console.log("aajaja", email, sub); */
  
  try {
    const response = await axios.post(`${API_CONST_URL}/check-user`, { email: email, password: sub });

    if (response.data && response.data.access_token) {
      await localStorage.setItem(TOKEN_KEY, dataEncrpt(response.data.access_token));
      navigate("/dashboard");
    } else {
    }
  } catch (error) {
    console.log("Error en revisar User ", error.response ? error.response.data : error.message);
  }
};
