import React, { useState, useEffect } from "react";
import axios from "axios";
import { ReactComponent as CloudIcon } from "bootstrap-icons/icons/x-square-fill.svg";
import { API_CONST_URL } from "../../../config/api/api"; // Asegúrate de importar correctamente la URL de la API
import dataDecrypt from "../../utils/Encryp/data-decrypt";

function EditUser({ user, onClose }) {
  const [role, setRole] = useState(user.role);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Estado para alternar visibilidad de contraseña
  const [isPasswordDisabled, setIsPasswordDisabled] = useState(false); // Estado para manejar la habilitación del campo de contraseña

  useEffect(() => {
    // Verifica si el usuario tiene un google_id_token
    if (user.google_id_token) {
      setIsPasswordDisabled(true); // Desactiva el campo de contraseña si existe el google_id_token
    }
  }, [user.google_id_token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("access_tokenL");
      if (!token) {
        throw new Error('No token found');
      }
    
      const decryptedToken = dataDecrypt(token);
      const response = await axios.put(
        `${API_CONST_URL}/resertRolp/${user.id}`,  
        {
          role,
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${decryptedToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data); // Manejar respuesta del servidor

      onClose(); // Cerrar modal después de la actualización exitosa

      window.location.reload(); // Recargar la página opcionalmente
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="modal-user">
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header bg-primary text-white"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h5 className="modal-title">Editar Usuario</h5>
              <span>
                <CloudIcon
                  width="40"
                  height="40"
                  color="white"
                  onClick={onClose} // Corregido el nombre de la función onClose
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Nombre</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Ingrese el nombre"
                        defaultValue={user.name}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="password">Contraseña</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          id="password"
                          placeholder="Ingrese la contraseña"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          disabled={isPasswordDisabled} // Desactiva el campo de contraseña si isPasswordDisabled es true
                        />
                       
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={togglePasswordVisibility}
                            disabled={isPasswordDisabled} // Desactiva el botón de mostrar/ocultar si el campo está deshabilitado
                          >
                            {showPassword ? "Ocultar" : "Mostrar"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="dni">DNI</label>
                      <input
                        type="text"
                        className="form-control"
                        id="dni"
                        placeholder="Ingrese el DNI"
                        defaultValue={user.dni}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="role">Rol</label>
                      <select
                        className="form-control"
                        id="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option  defaultValue>Seleccionar rol</option>
                        <option value="1">Proveedor</option>
                        <option value="2">Administrador</option>
                        <option value="5">Cliente</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Guardar Cambios
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default EditUser;
