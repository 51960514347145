import axios from 'axios';
import { API_CONST_URL } from '../../../config/api/api';
import dataDecrypt from '../../utils/Encryp/data-decrypt';

const TOKEN_KEY = "access_tokenL";

const handleLogout = async (navigate) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    throw new Error('No token found');
  }

  const decryptedToken = dataDecrypt(token);
  try {
    await axios.post(`${API_CONST_URL}/logout`, {}, {
      headers: {
        Authorization: `Bearer ${decryptedToken}`
      }
    });
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("]nrLg&OZNsKY6(O");
    localStorage.removeItem(`+Z7=1c]"BpK6*;q`);
    localStorage.removeItem('user_role'); 
    localStorage.removeItem('userData')
    navigate('/login');
  } catch (error) {
    console.error("Error during logout:", error.response ? error.response.data : error.message);
    localStorage.clear();
    navigate('/login');
  }
};

export default handleLogout;
