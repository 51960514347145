import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/index.css";

import RoutesHome from "./components/auth/Routes/Routes";
const TOKEN_KEY = "access_tokenL";
const PrivateRoute = ({ element, ...rest }) => {
  useEffect(() => {
    const isAuthenticated = localStorage.getItem(TOKEN_KEY);
    if (isAuthenticated) {
      let timeout;
      const resetLocalStorage = () => {
        localStorage.clear();
      };
      const resetTimer = () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(resetLocalStorage, 86400000); 
      };
      resetTimer();
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, []);

  const isAuthenticated = localStorage.getItem(TOKEN_KEY);

  return isAuthenticated ? element : <Navigate to="/login" />;
};

const PublicRoute = ({ element, ...rest }) => {
  const isAuthenticated = localStorage.getItem(TOKEN_KEY);

  return isAuthenticated ? <Navigate to="/dashboard" /> : element;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<PublicRoute element={<Login />} />} />
        <Route
          path="/dashboard/*"
          element={<PrivateRoute element={<RoutesHome />} />}
        />
        <Route path="*" element={<PrivateRoute element={<RoutesHome />} />} />
      </Routes>
    </Router>
  );
}

export default App;
