import React, { useState, useEffect, useContext } from "react";
import "../sidebars/headerSidebar.css";
import { ReactComponent as BellIcon } from "bootstrap-icons/icons/bell-fill.svg";
import { ReactComponent as ChatIcon } from "bootstrap-icons/icons/chat-square-text-fill.svg";
import { ReactComponent as BrightnessIcon } from "bootstrap-icons/icons/brightness-high.svg";
import { ReactComponent as ArrowsIcon } from "bootstrap-icons/icons/arrows-fullscreen.svg";
import { ReactComponent as ListIcon } from "bootstrap-icons/icons/list.svg";
import icono from "../../assets/logo.png";
import ico from "../../assets/descarga (3).png";
import { SidebarContext } from "./SidebarContext";

function HeaderSidebar() {
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  const notificationCount = 5;
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSidebarOpenChat, setIsSidebarOpenChat] = useState(false);
  const handleFullScreenToggle = () => {
    if (!isFullScreen) {
      // Entrar en modo de pantalla completa
      document.documentElement.requestFullscreen();
    } else {
      // Salir del modo de pantalla completa
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    // Función que maneja el cambio de estado de pantalla completa
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    // Suscribirse al evento fullscreenchange
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    // Limpiar el evento al desmontar el componente
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    const savedIsDarkMode = JSON.parse(localStorage.getItem("isDarkMode"));
    if (savedIsDarkMode) {
      setIsDarkMode(savedIsDarkMode);
      if (savedIsDarkMode) {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
    }
  }, []);
  const handleDarkModeToggle = () => {
    const newIsDarkMode = !isDarkMode;
    setIsDarkMode(newIsDarkMode);
    document.body.classList.toggle("dark-mode");
    localStorage.setItem("isDarkMode", JSON.stringify(newIsDarkMode));
  };
  const toggleSidebarChats = () => {
    setIsSidebarOpenChat(!isSidebarOpenChat);
  };

  return (
    <div
      className="content-header-sidebar"
      style={{ width: "100%", height: "auto" }}
    >
      <div className="Menu-responsive">
        <div className="menu-responsive-content">
          <ListIcon
            onClick={toggleSidebar}
            width="40"
            height="40"
            color="gray"
            style={{ cursor: "pointer", paddingTop: "10px" }}
          />
          <img
            src={icono}
            alt=""
            style={{ width: "150px", height: "30px", marginTop: "10px" }}
          />
        </div>
        <div className="content-text-info-ventas">
          <p>
            Dashboard
            <span className="span-ventas">Tienes nuevas ventas</span>
          </p>
        </div>
      </div>

      <div className="content-alerts-notificaciones">
        <div className="content-alert" onClick={handleFullScreenToggle}>
          <ArrowsIcon
            width="20"
            height="20"
            color="gray"
            className="alert-icon-hover"
          />
        </div>
        {/* <div className="content-alert" onClick={toggleSidebarChats}>
          <ChatIcon
            width="20"
            height="20"
            color="gray"
            className="alert-icon-hover"
          />
        </div> */}
        {/* <div className="content-alert" onClick={handleDarkModeToggle}>
          <BrightnessIcon
            width="20"
            height="20"
            color="gray"
            className="alert-icon-hover"
          />
        </div>
 */}
        {/* <div
          className="notification"
          style={{ position: "relative", cursor: "pointer" }}
        >
          <BellIcon width="20" height="20" color="white" />
          {notificationCount > 0 && (
            <span
              style={{
                position: "absolute",
                top: "1px",
                right: "2px",
                backgroundColor: "skyblue",
                color: "white",
                borderRadius: "50%",
                padding: "2px 6px",
                fontSize: "10px",
              }}
            >
              {notificationCount}
            </span>
          )}
        </div> */}
      </div>
      {/*   <div
        className={`Alert-chat-online-transparent ${
          isSidebarOpenChat ? "open" : "closed"
        }`}
        style={{
          right: isSidebarOpenChat ? "0px" : "-2500px",
        }}>
        <div className="content-chat-online-chats">
          <div className="content-header-chats-online">
            <div className="content-header">
              <h4>Franklin Flores</h4>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: ".5rem",
                  fontSize:"15px",
                  fontWeight:"300"
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50px",
                    background: "#B9D835",
                  }}
                ></div>{" "}
                online
              </span>
            </div>
            <div className="content-chats-headers-ssetings">
              <div className="ssetings-poinst">...</div>
              <div className="close-chats" onClick={toggleSidebarChats}>
                x
              </div>
            </div>
          </div>
          <div className="body-content">
            <div className="content-body-chats">
              <div className="cards-chats-message">
                <div className="content-chats-foto-name">
                  <img src={ico} alt="" style={{ marginRight: "5px" }} />
                  <div className="content-info">
                    <span className="name">Franklin Flores</span>
                    <span className="time">2mins</span>
                  </div>
                </div>
                <div
                  className="menssage-content-chats"
                  style={{ paddingLeft: "1rem" }}
                >
                  <span>
                    ¿Qué probabilidades hay de que recomiende nuestra empresa a
                    sus amigos? y familia ?
                  </span>
                </div>
              </div>

             

              <div className="cards-chats-message">
                <div className="content-chats-foto-name-users">
                  <img src={ico} alt="" style={{ marginLeft: "15px" }} />
                  <div className="content-info-user">
                    <span className="name">Jeferson</span>
                    <span className="time">2mins</span>
                  </div>
                </div>
                <div className="menssage-content-chats-users">
                  <span>
                    ¿Qué probabilidades hay de que recomiende nuestra empresa a
                    sus amigos? y familia ?
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content-chats">
            <div className="content-input-chats-text-content">
              <textarea
                className="input-chats-text-content"
                name=""
                id=""
              ></textarea>
            </div>
            <div className="send--content-buttons-chats">
              <button>Enviar</button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default HeaderSidebar;
