import React from 'react'
import { API_CONST_URL } from '../../../../config/api/api'
import axios from 'axios';
import dataDecrypt from '../../Encryp/data-decrypt';

const TOKEN_KEY = "access_tokenL";

const UpdateServiceOf= async(Serviceofid, UpdateDataOf)=> {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    throw new Error('No token found');
  }

  const decryptedToken = dataDecrypt(token);
  const updateServiceOf = `${API_CONST_URL}/updateProvided/${Serviceofid}`;
  const headers ={
    'Authorization': `Bearer ${decryptedToken}`,
        'Content-Type': 'application/json'
  };
  try{
    const response = await axios.put(updateServiceOf,UpdateDataOf,{
        headers
    });
    if (response.status===200){
        console.log('Service Provided Update seccessfully',response.data);
        return response.data;
    }
    else{
        console.error('Faild to update service provided',response.data);
        throw new Error('Faild to update service provided');
    }
  } catch(error){
    console.error('Error updating service provided',error);
        throw new Error('Error updating service provided')
  }
}

export default UpdateServiceOf