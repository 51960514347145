import axios from 'axios';
import { API_CONST_URL } from '../../../../config/api/api';
import dataDecrypt from '../../Encryp/data-decrypt';
const TOKEN_KEY = "access_tokenL";

function CreateServiceOf(serviceId, serviceData) {
    const url = `${API_CONST_URL}/createProvided/${serviceId}`;  // Reemplaza con tu URL y endpoint correctos
    const token = localStorage.getItem(TOKEN_KEY);  // Aquí asumo que estás usando localStorage para el token, ajusta según tu implementación
    if (!token) {
        throw new Error('No token found');
      }
    
      const decryptedToken = dataDecrypt(token);
    return axios.post(url, serviceData, {
        headers: {
            'Authorization': `Bearer ${decryptedToken}`,
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
        console.log('Servicio creado exitosamente:', response.data);
        return response.data;  // Retorna los datos de respuesta si es necesario
    })
    .catch(error => {
        console.error('Error al crear servicio:', error);
        throw error;  // Propaga el error para manejarlo en el componente que llama a esta función
    });
}

export default CreateServiceOf;
