import React, { useEffect, useState } from "react";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { ReactComponent as DatabaseIcon } from "bootstrap-icons/icons/database-fill.svg";
import { ReactComponent as CheckIcon } from "bootstrap-icons/icons/check-circle-fill.svg";
import { ReactComponent as XcircleIcon } from "bootstrap-icons/icons/x-circle-fill.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { API_CONST_URL } from "../../config/api/api";
import dataDecrypt from "../utils/Encryp/data-decrypt";
import "./Content.css";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const TOKEN_KEY = "access_tokenL";

function Card() {
  const [servicesData, setServicesData] = useState([]);
  const [additionalServicesData, setAdditionalServicesData] = useState([]);
  const [chartDataPie, setChartDataPie] = useState(null);
  const [chartDataBar, setChartDataBar] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
          throw new Error("No token found");
        }
        const decryptedToken = dataDecrypt(token);
        const response = await axios.post(`${API_CONST_URL}/user/count`, null, {
          headers: {
            Authorization: `Bearer ${decryptedToken}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.data) {
          throw new Error("Failed to fetch services count");
        }

        const {
          total_de_servicios,
          Servicios_activos,
          Servicios_inactivos,
          servicios_con_cantidad_ofrecida,
        } = response.data;

        const mainStats = [
          { key: "total_de_servicios", value: total_de_servicios },
          { key: "Servicios_activos", value: Servicios_activos },
          { key: "Servicios_inactivos", value: Servicios_inactivos },
        ];

        setServicesData(mainStats);
        setAdditionalServicesData(servicios_con_cantidad_ofrecida);

        const serviciosActivos = Servicios_activos || 0;
        const serviciosInactivos = Servicios_inactivos || 0;

        setChartDataPie({
          labels: ["Servicios activos", "Servicios inactivos"],
          datasets: [
            {
              data: [serviciosActivos, serviciosInactivos],
              backgroundColor: ["#4CAF50", "#FF5252"],
              hoverBackgroundColor: ["#66BB6A", "#FF867F"],
              borderWidth: 2,
            },
          ],
        });

        const nombresServicios = servicios_con_cantidad_ofrecida.map(
          (servicio) => servicio.nombre
        );
        const cantidadesOfrecidas = servicios_con_cantidad_ofrecida.map(
          (servicio) => servicio.sub_servicios_ofrecidos
        );

        setChartDataBar({
          labels: nombresServicios,
          datasets: [
            {
              label: "Cantidad de Subservicios Ofrecidos",
              data: cantidadesOfrecidas,
              backgroundColor: "rgba(54, 162, 235, 0.6)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching services:", error.message);
      }
    };

    fetchData();
  }, []);

  const getIcon = (key) => {
    switch (key) {
      case "total_de_servicios":
        return <DatabaseIcon width={40} height={40} color="#007BFF" />;
      case "Servicios_activos":
        return <CheckIcon width={40} height={40} color="#28A745" />;
      case "Servicios_inactivos":
        return <XcircleIcon width={40} height={40} color="#DC3545" />;
      default:
        return null;
    }
  };

  return (
    <div className="content--card-td">
      <div className="content--cards-charts-count">
        <div className="content-cards-count-cards">
          {servicesData.map((service, index) => (
            <div className="cards--count-content" key={index}>
              <div className=" card--count h-100 text-center custom-card-bg">
                <div className="card-body d-flex flex-column align-items-center">
                  <div className="card--cover mb-3">{getIcon(service.key)}</div>
                  <div className="card--title">
                    <h5 className="card-title">
                      {`${service.key.replace("_", " ")}: ${service.value}`}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="card  card--grafico-content-grafico-barras-content">
          <div className="">
            <div
              className="content-grafico-barras"
              style={{
                width: "100%",
                height: "400px",
                display: "flex",
                justifyContent: "center",
              }}>
              {chartDataBar ? (
                <Bar
                  data={chartDataBar}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          precision: 0,
                        },
                      },
                      x: {
                        type: "category",
                        labels: chartDataBar.labels,
                        display: true,
                        title: {
                          display: true,
                          color: "#333",
                          font: {
                            size: 16,
                            weight: "bold",
                          },
                        },
                      },
                    },
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (tooltipItem) =>
                            `Cantidad: ${tooltipItem.raw}`,
                        },
                      },
                      legend: {
                        display: true,
                        position: "top",
                        labels: {
                          font: {
                            size: 12,
                            family:
                              "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                            color: "#333",
                          },
                        },
                      },
                    },
                  }}
                />
              ) : (
                <p>Loading chart...</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="grafico mt-4">
        <div className="col-12 content--cardUser">
          <div className="card card--grafico">
            <div
              className="card-body"
              style={{
                width: "100%",
                height: "400px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {chartDataPie ? (
                <Pie
                  data={chartDataPie}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (tooltipItem) =>
                            `${tooltipItem.label}: ${tooltipItem.raw}`,
                        },
                      },
                      legend: {
                        display: true,
                        position: "top",
                        labels: {
                          font: {
                            size: 12,
                            family:
                              "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                            color: "#333",
                          },
                        },
                      },
                    },
                  }}
                />
              ) : (
                <p>Loading chart...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Card;