import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { API_CONST_URL } from "../../config/api/api";
import { fetchUserData } from "../utils/utils/ApiMeUtils";
import foto from "../../assets/descarga (3).png";
import LazyLoad from "react-lazyload";
import ico from "../../assets/logo.png";
import "./Profile.css";
import { ReactComponent as PersonIcon } from "bootstrap-icons/icons/person-check-fill.svg";
import { ReactComponent as GeoIcon } from "bootstrap-icons/icons/geo-fill.svg";
import { ReactComponent as PatchIcon } from "bootstrap-icons/icons/patch-check-fill.svg";
import { ReactComponent as EnvelopeIcon } from "bootstrap-icons/icons/envelope-arrow-down-fill.svg";
import { dataEncrpt } from "../utils/Encryp/data-encrypt";
import dataDecrypt from "../utils/Encryp/data-decrypt";
import ModalData from "../dataAdmin/ModalData";
const TOKEN_KEY = "access_tokenL";

const USER_ROLE_KEY = '+Z7=1c]"BpK6*;q';
function Profile() {
  const [loading, setLoading] = useState(false);
  const [editDataAdmin, seteditDataAdmin] = useState(null);
  const [userData, setUserData] = useState({
    nombre: "",
    apellido: "",
    dni: "",
    celular: "",
    email: "",
    photo: "",
    fechaNacimiento: "",
    departamentos: [],
    provincias: [],
    distritos: [],
    role: "",
    avatar: "",
  });

  useEffect(() => {
    const fetchUserDataWithCache = async () => {
      try {
        setLoading(true);
        let cachedUserData = JSON.parse(localStorage.getItem("userData"));
        if (!cachedUserData) {
          cachedUserData = await fetchUserData();
          localStorage.setItem("userData", JSON.stringify(cachedUserData));
        }
        setUserData(cachedUserData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    fetchUserDataWithCache();
  }, []);

  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("TOKEN_KEY");
        const userDataResponse = await fetchUserData();
        let encryptedRole = localStorage.getItem(USER_ROLE_KEY);
        let role;
        if (encryptedRole) {
          role = dataDecrypt(encryptedRole);
        } else {
          role = userDataResponse.role;
          localStorage.setItem(USER_ROLE_KEY, dataEncrpt(role));
        }
        setUserRole(role);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const closeEditModalDataAdmin = () => {
    seteditDataAdmin(null);
  };

  const openEditModalDataAdmin = async () => {
    try {
      const response = await axios.post(
        "https://apiworkpe.work-pe.com/api/auth/all/datos"
      );
      // Suponiendo que la respuesta tiene una estructura similar a la que has mostrado
      const userDataFromApi = response.data.data[0]; // Ajusta según la estructura real de la respuesta
      seteditDataAdmin(userDataFromApi);
    } catch (error) {
      console.error("Error fetching data for modal:", error);
    }
  };

  return (
    <div className="content-profile">
      {loading ? (
        <div className="spinner-container">
          <img src={ico} alt="" />
          <Spinner animation="border" variant="primary" role="status" />
        </div>
      ) : (
        <div
          className=""
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <div className="profile--content ">
            <div className="content-profile-foto-info">
              <img
                className="img-user-profile"
                src={`${userData.photo}`}
                alt=""
              />
            </div>
            <div className="info-user-profile">
              <div className="info-name-profile">
                <p>{userData.name}</p>
                <PatchIcon
                  width={15}
                  height={15}
                  style={{ marginTop: "-23", color: "#53d3e7" }}
                />
                <span>Version Pro</span>
              </div>
              <p>
                <span>
                  <PersonIcon width={15} height={15} /> {userData.role}
                </span>

                <span>
                  <GeoIcon width={15} height={15} />{" "}
                  {userData.departamentos && userData.departamentos.length > 0
                    ? userData.departamentos[0].name
                    : ""}
                </span>
                <span>
                  <EnvelopeIcon width={15} height={15} /> {userData.email}
                </span>
              </p>
            </div>
            {userRole === "Administrador" && (
              <button
                className="button-absolute button-profile-edit"
                onClick={() => openEditModalDataAdmin()}
              >
                editar Datos
              </button>
            )}
          </div>
          <div className="">
            <div className="profile--content-datails">
              <div className="header--details--profile">
                <h3>Detalles de perfil</h3>
               {/*  <button className="button-profile-edit">Editar Perfil</button> */}
              </div>
            </div>
            <div className="profile--content-datails-body">
              <div className="content--details-profile">
                <div className="row mb-7">
                  <label htmlFor="">nombre completo</label>
                  <div>
                    <span>
                      {userData.name}, {userData.apellido}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label htmlFor="">Dni</label> <span>{userData.dni}</span>
                </div>
                <div className="row mb-7">
                  <label htmlFor="">Número de celular</label>{" "}
                  <span>{userData.celular}</span>
                </div>
                <div className="row mb-7">
                  <label htmlFor="">Departamento</label>{" "}
                  <span>
                    {userData.departamentos && userData.departamentos.length > 0
                      ? userData.departamentos[0].name
                      : ""}
                  </span>
                </div>
                <div className="row mb-7">
                  <label htmlFor="">Provincia</label>{" "}
                  <span>
                    {userData.provincias && userData.provincias.length > 0
                      ? userData.provincias[0].name
                      : ""}
                  </span>
                </div>
                <div className="row mb-7">
                  <label htmlFor="">Distrito</label>{" "}
                  <span>
                    {userData.distritos && userData.distritos.length > 0
                      ? userData.distritos[0].name
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/*   <div className="">
            <div className="profile--content-datails">
              <div className="header--details--profile">
                <h3>Notificaciones</h3>
              </div>
            </div> */}

          {/* <div c */}
          {/* lassName="profile--content-datails-body"> */}
          {/*  <div className="content--details-profile">
                <div className="table-responsive">
                  <table className="">
                    <tbody>
                      <tr>
                        <th>
                          <label htmlFor="">Notificaciones</label>{" "}
                          <input type="checkbox" name="" id="" />
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="row mb-7"
                  style={{ justifyContent: "space-between" }}
                >
                  <label htmlFor="">Notificaciones</label>
                  <div></div>
                </div>
                <div className="row mb-7">
                  <label htmlFor="">Dni</label>
                </div>
                <div className="row mb-7">
                  <label htmlFor="">Número de celular</label>{" "}
                </div>
                <div className="row mb-7">
                  <label htmlFor="">Departamento</label>{" "}
                </div>
                <div className="row mb-7">
                  <label htmlFor="">Provincia</label>{" "}
                </div>
                <div className="row mb-7">
                  <label htmlFor="">Distrito</label>{" "}
                </div>
              </div> */}
          {/* </div> */}

          {/* </div> */}
        </div>
      )}

      {editDataAdmin && (
        <ModalData user={editDataAdmin} onClose={closeEditModalDataAdmin} />
      )}
    </div>
  );
}

export default Profile;
{
  /* Configuración del perfil */
}
{
  /*  <div className="col-md-6 border-end">
              <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4 className="text-center">Configuración del Perfil</h4>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <label className="labels">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userData.name}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labels">Apellido</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userData.apellido}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label className="labels">DNI</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userData.dni}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labels">Número de Celular</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userData.celular}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <label className="labels">Correo Electrónico</label>
                    <input
                      type="email"
                      className="form-control"
                      value={userData.email}
                      readOnly
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="labels">Fecha de nacimiento</label>
                    <input
                      type="date"
                      className="form-control"
                      value={userData.fechaNacimiento}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label className="labels">Departamento</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        userData.departamentos &&
                        userData.departamentos.length > 0
                          ? userData.departamentos[0].name
                          : ""
                      }
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labels">Provincia</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        userData.provincias && userData.provincias.length > 0
                          ? userData.provincias[0].name
                          : ""
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <label className="labels">Distrito</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        userData.distritos && userData.distritos.length > 0
                          ? userData.distritos[0].name
                          : ""
                      }
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div> */
}

{
  /* Rol del usuario */
}
{
  /* <div className="col-md-3">
              <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center experience">
                  <span className="fw-bold mr-5">Rol</span>
                  <span className="border px-3 py-1 rounded-pill add-experience">
                    {userData.role}
                  </span>
                </div>
              </div>
            </div> */
}
