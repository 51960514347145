import { API_CONST_URL } from '../../../../config/api/api';
import axios from 'axios';
import dataDecrypt from '../../Encryp/data-decrypt';

const TOKEN_KEY = "access_tokenL";

function createServicio(servicetdData) {
  const url = `${API_CONST_URL}/create/service`;
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    throw new Error('No token found');
  }

  const decryptedToken = dataDecrypt(token);
  // Mostrar los datos que se enviarán en formato JSON
  console.log('Datos a enviar al servicio:', JSON.stringify(servicetdData, null, 2));

  return axios.post(url, servicetdData, {
    headers: {
      'Authorization': `Bearer ${decryptedToken}`,
      'Content-Type': 'application/json',
    }
  })
  .then(response => {
    console.log('Servicio creado exitosamente:', response.data);
    return response.data;
  })
  .catch(error => {
    console.error('Error al crear servicio:', error);
    throw error;
  });
}

export default createServicio;
