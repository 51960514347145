import axios from "axios";
import { API_CONST_URL } from "../../../config/api/api";
import { dataEncrpt } from "../../utils/Encryp/data-encrypt";

const TOKEN_KEY = "access_tokenL";
const handleLogin = async (email, password, navigate) => {
  try {
    const response = await axios.post(`${API_CONST_URL}/login`, {
      email,
      password,
    });
    const { access_token } = response.data;

    if (response.status === 200) {
      localStorage.setItem(TOKEN_KEY, dataEncrpt(access_token));
      navigate("/dashboard");
    } else {
      alert("Credenciales inválidas");
    }
  } catch (error) {
    console.error(
      "Error during login:",
      error.response ? error.response.data : error.message
    );
    
  }
};

export default handleLogin;
