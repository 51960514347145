import axios from 'axios';
import { API_CONST_URL } from "../../../../config/api/api";
import dataDecrypt from '../../../utils/Encryp/data-decrypt';

const TOKEN_KEY = "access_tokenL";

const handleUpdateService = async (serviceId, updatedData) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
        throw new Error('No token found');
      }
    
      const decryptedToken = dataDecrypt(token);
    const updateServiceUrl = `${API_CONST_URL}/updateservice/${serviceId}`;
    const headers = {
        'Authorization': `Bearer ${decryptedToken}`,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axios.put(updateServiceUrl, updatedData, { headers });

        if (response.status === 200) {
            console.log('Service updated successfully:', response.data);
            return response.data; // Opcional: devolver datos actualizados si es necesario
        } else {
            console.error('Failed to update service:', response.data);
            throw new Error('Failed to update service');
        }
    } catch (error) {
        console.error('Error updating service:', error);
        throw new Error('Error updating service');
    }
};

export default handleUpdateService;

