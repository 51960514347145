import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, Spinner } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { API_CONST_URL } from "../../config/api/api";
import { ReactComponent as FacebookIcon } from "bootstrap-icons/icons/facebook.svg";
import { ReactComponent as WhatsappIcon } from "bootstrap-icons/icons/whatsapp.svg";
import { ReactComponent as PencilIcon } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as EyesIcon } from "bootstrap-icons/icons/eye.svg";
import { fetchUserData } from "../utils/utils/ApiMeUtils";
import EditUser from "./Edit-user/EditUser";
import EditService from "./EditService/EditService";
import ServiciosOfrecidos from "./ServiciosOfrecidos/ServiciosOfrecidos";
import handleUpdateService from "./Update/Update-Service/UpdateService";
import ReactPaginate from "react-paginate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import dataDecrypt from "../utils/Encryp/data-decrypt";
import foto from "../../assets/descarga (3).png";
import { dataEncrpt } from "../utils/Encryp/data-encrypt";
import "./ListService.css";
import ico from "../../assets/logo.png";
const TOKEN_KEY = "access_tokenL";
const USER_ROLE_KEY = `+Z7=1c]"BpK6*;q`;
function ListService() {
  const [userData, setUserData] = useState({});
  const [services, setServices] = useState([]);
  const [users, setUsers] = useState([]);
  const [editUserId, setEditUserId] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [editService, setEditService] = useState(null);
  const [listServicesOf, setListServicesOf] = useState(null);
  const [loading, setLoading] = useState(false); // Estado para manejar la carga
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 8;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem(TOKEN_KEY); // Obtener el token de localStorage
        const userDataResponse = await fetchUserData();
        setUserData(userDataResponse);

        // Verificar si el rol del usuario ya está en el localStorage
        let encryptedRole = localStorage.getItem(USER_ROLE_KEY);
        let role;

        if (encryptedRole) {
          role = dataDecrypt(encryptedRole); // Desencriptar el rol almacenado
        } else {
          role = userDataResponse.role;
          // Guardar el rol encriptado en localStorage para futuras consultas
          localStorage.setItem(USER_ROLE_KEY, dataEncrpt(role));
        }

        // Lógica para mostrar contenido basado en el rol
        if (role === "Proveedor") {
          const servicesResponse = await fetchServices(token);
          setServices(servicesResponse.services);
        } else if (role === "Administrador") {
          const usersResponse = await fetchUsers(token);
          setUsers(usersResponse.users);
        }

        setLoading(false); // Marcar carga como completada
      } catch (error) {
        console.error(error);
        setLoading(true); // Manejar error y marcar carga como completada
      }
    };

    fetchData();
  }, []);

  const fetchServices = async (token) => {
    if (!token) {
      throw new Error("No token found");
    }

    const decryptedToken = dataDecrypt(token);
    const response = await fetch(`${API_CONST_URL}/user/services`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${decryptedToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch services");
    }
    const data = await response.json();
    localStorage.setItem("services", JSON.stringify(data));
    return data;
  };

  const fetchUsers = async (token) => {
    if (!token) {
      throw new Error("No token found");
    }

    const decryptedToken = dataDecrypt(token);
    const response = await fetch(`${API_CONST_URL}/users`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${decryptedToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch users");
    }
    const data = await response.json();

    return data;
  };

  const openEditModal = (userId) => {
    setEditUserId(userId);
    const selectedUser = users.find((user) => user.id === userId);
    setEditUser(selectedUser);
  };

  const openEditServiceModal = (serviceId) => {
    const selectedService = services.find(
      (service) => service.id === serviceId
    );
    setEditService(selectedService);
  };
  const openEditServiceOfreModal = (serviceId) => {
    const selectedService = services.find(
      (service) => service.id === serviceId
    );
    setListServicesOf(selectedService);
  };

  const closeEditModal = () => {
    setEditUserId(null);
    setEditUser(null);
    setEditService(null);
    setListServicesOf(null);
  };
  const formatTime = (time) => {
    const [hour, minute, second] = time.split(":");
    const hourInt = parseInt(hour);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12;
    return `${formattedHour}:${minute} ${ampm}`;
  };

  const handleSwitchChange = async (serviceId, isChecked) => {
    try {
      const updatedData = {
        Activo: isChecked ? 1 : 0,
      };
      await handleUpdateService(serviceId, updatedData);
      // Actualizar localmente el estado del servicio después de la respuesta exitosa de la API si es necesario
      const updatedServices = services.map((service) =>
        service.id === serviceId
          ? { ...service, Activo: isChecked ? 1 : 0 }
          : service
      );
      setServices(updatedServices);
    } catch (error) {
      console.error("Error updating service:", error);
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const paginatedServices = services.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const paginatedUsers = users.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div className="my-5 table-scroll" style={{ width: "100%", height:"100%" }}>
      {loading ? (
        <div className="spinner-container">
          <img src={ico} alt="" />
          <Spinner animation="border" variant="primary" role="status"></Spinner>
        </div>
      ) : (
        <>
          <div className="table-responsive rounded bg-white p-4">
            {loading ? (
              <div className="spinner-container">
                <img src={ico} alt="" />
                <Spinner
                  animation="border"
                  variant="primary"
                  role="status"
                ></Spinner>
              </div>
            ) : (
              <>
                {userData.role === "Proveedor" && (
                  <>
                    <table className="table table-hover table-bordered">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">Imagen</th>
                          <th scope="col">WhatsApp</th>
                          <th scope="col">Facebook</th>
                          <th scope="col">Nombre</th>
                          <th scope="col">District</th>
                          <th scope="col">Province</th>
                          <th scope="col">Description</th>
                          <th scope="col">Estado</th>
                          <th scope="col">Ver</th>
                          <th scope="col">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedServices.map((service) => (
                          <tr key={service.id}>
                            <td>
                              <LazyLoad height={50} offset={50} once>
                                <img
                                  src={service.imageUrl}
                                  alt="Service"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    marginLeft:"15px"
                                  }}
                                />
                              </LazyLoad>
                            </td>
                            <td>
                              <a
                                href={`https://wa.me/${service.WhatsApp}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <WhatsappIcon
                                  className="text-success"
                                  width="30"
                                  height="30"
                                />
                              </a>
                            </td>
                            <td>
                              <a
                                href={service.Facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <FacebookIcon
                                  className="text-primary"
                                  width="30"
                                  height="30"
                                />
                              </a>
                            </td>
                            <td
                              style={{
                                maxWidth: "180px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{service.name}</Tooltip>}
                              >
                                <span
                                  style={{
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {service.name}
                                </span>
                              </OverlayTrigger>
                            </td>

                            <td>{service.district}</td>
                            <td>{service.province}</td>
                            <td
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "50px",
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>{service.description}</Tooltip>
                                }
                              >
                                <span
                                  style={{
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {service.description}
                                </span>
                              </OverlayTrigger>
                            </td>
                            <td
                              style={{ alignItems: "center", textAlign: "end" }}
                            >
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id={`flexSwitchCheck-${service.id}`}
                                  style={{
                                    backgroundColor:
                                      service.Activo === 1 ? "green" : "",
                                  }}
                                  checked={service.Activo === 1}
                                  onChange={(e) =>
                                    handleSwitchChange(
                                      service.id,
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td
                              style={{ cursor: "pointer", textAlign: "center" }}
                              onClick={() =>
                                openEditServiceOfreModal(service.id)
                              }
                            >
                              <EyesIcon
                                className="text-primary"
                                width="40"
                                height="40"
                              />
                            </td>
                            <td
                              style={{ cursor: "pointer", textAlign: "center" }}
                              onClick={() => openEditServiceModal(service.id)}
                            >
                              <PencilIcon
                                width="30"
                                height="30"
                                color="green"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ReactPaginate
                      previousLabel={"Anterior"}
                      nextLabel={"Siguiente"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(services.length / itemsPerPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previo  usClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </>
                )}
              </>
            )}
            {userData.role === "Administrador" && (
              <>
                <table className="table table-hover table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th style={{ width: "auto" }} scope="col">
                        ID
                      </th>
                      <th scope="col">Foto</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Email</th>
                      <th scope="col">DNI</th>
                      <th scope="col">Rol</th>
                      <th
                        style={{ width: "50px", cursor: "pointer" }}
                        scope="col"
                      >
                        Editar
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedUsers.map((user) => (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td style={{ textAlign: "center" }}>
                          <LazyLoad height={30} offset={100} once>
                            {user.photo ? (
                              <img
                                src={`${user.photo}`}
                                alt={user.name}
                                width="30"
                                height="30"
                                className="rounded-circle"
                              />
                            ) : (
                              <img
                                src={foto}
                                alt="Default"
                                width="30"
                                height="30"
                                className="rounded-circle"
                              />
                            )}
                          </LazyLoad>
                        </td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.dni}</td>
                        <td>{user.role}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => openEditModal(user.id)}
                        >
                          <PencilIcon width="30" height="30" color="green" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Siguiente"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(users.length / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </>
            )}
            {editUser && <EditUser user={editUser} onClose={closeEditModal} />}
            {editService && (
              <EditService service={editService} onClose={closeEditModal} />
            )}
            {listServicesOf && (
              <ServiciosOfrecidos
                service={listServicesOf}
                onClose={closeEditModal}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ListService;

/* import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { API_CONST_URL } from "../../config/api/api";
import { ReactComponent as FacebookIcon } from "bootstrap-icons/icons/facebook.svg";
import { ReactComponent as WhatsappIcon } from "bootstrap-icons/icons/whatsapp.svg";
import { ReactComponent as PencilIcon } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as EyesIcon } from "bootstrap-icons/icons/eye.svg";
import { fetchUserData } from "../utils/utils/ApiMeUtils";
import EditUser from "./Edit-user/EditUser";
import EditService from "./EditService/EditService";
import ServiciosOfrecidos from "./ServiciosOfrecidos/ServiciosOfrecidos";
import handleUpdateService from "./Update/Update-Service/UpdateService";

const TOKEN_KEY = "access_token";

function ListService() {
  const [userData, setUserData] = useState({});
  const [services, setServices] = useState([]);
  const [users, setUsers] = useState([]);
  const [editUserId, setEditUserId] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [editService, setEditService] = useState(null);
  const [listServicesOf, setListServicesOf] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem(TOKEN_KEY);
        const userDataResponse = await fetchUserData();
        setUserData(userDataResponse);

        if (userDataResponse.role === "Proveedor") {
          const servicesResponse = await fetchServices(token);
          setServices(servicesResponse.services);
        } else if (userDataResponse.role === "Administrador") {
          const usersResponse = await fetchUsers(token);
          setUsers(usersResponse.users);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const fetchServices = async (token) => {
    const response = await fetch(`${API_CONST_URL}/user/services`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch services");
    }
    const data = await response.json();
    return data;
  };

  const fetchUsers = async (token) => {
    const response = await fetch(`${API_CONST_URL}/users`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch users");
    }
    const data = await response.json();
    return data;
  };

  const openEditModal = (userId) => {
    setEditUserId(userId);
    const selectedUser = users.find((user) => user.id === userId);
    setEditUser(selectedUser);
  };

  const openEditServiceModal = (serviceId) => {
    const selectedService = services.find(
      (service) => service.id === serviceId
    );
    setEditService(selectedService);
  };
  const openEditServiceOfreModal = (serviceId) => {
    const selectedService = services.find(
      (service) => service.id === serviceId
    );
    setListServicesOf(selectedService);
  };

  const closeEditModal = () => {
    setEditUserId(null);
    setEditUser(null);
    setEditService(null);
    setListServicesOf(null);
  };
  const formatTime = (time) => {
    const [hour, minute, second] = time.split(":");
    const hourInt = parseInt(hour);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12;
    return `${formattedHour}:${minute} ${ampm}`;
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleInputChange = () => {
    setIsChecked(!isChecked);
  };
  const handleSwitchChange = async (serviceId, isChecked) => {
    try {
      const updatedData = {
        Activo: isChecked ? 1 : 0,
      };
      await handleUpdateService(serviceId, updatedData);
      // Actualizar localmente el estado del servicio después de la respuesta exitosa de la API si es necesario
      const updatedServices = services.map((service) =>
        service.id === serviceId ? { ...service, Activo: isChecked ? 1 : 0 } : service
      );
      setServices(updatedServices);
    } catch (error) {
      console.error("Error updating service:", error);
    }
  };
  return (
    <div className="my-5" style={{ width: "100%" }}>
      <div className="table-responsive rounded bg-white p-4">
        {userData.role === "Proveedor" && (
          <table className="table table-hover table-bordered">
            <thead className="table-light">
              <tr>
                <th scope="col">Imagen</th>
                <th scope="col">WhatsApp</th>
                <th scope="col">Facebook</th>
                <th scope="col">Nombre</th>
                <th scope="col">District</th>
                <th scope="col">Province</th>
                <th scope="col">Description</th>
                <th scope="col">Horario de atención</th>
                <th scope="col">Estado</th>
                <th scope="col">Ver</th>
                <th scope="col">Editar</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service) => (
                <tr key={service.id}>
                  <td>
                    <img
                      src={service.imageUrl}
                      alt="Service"
                      style={{ width: "50px",height:"50px", borderRadius: "50%" }}
                    />
                  </td>
                  <td>
                    <a
                      href={`https://wa.me/${service.WhatsApp}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <WhatsappIcon
                        className="text-success"
                        width="40"
                        height="40"
                      />
                    </a>
                  </td>
                  <td>
                    <a
                      href={service.Facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <FacebookIcon
                        className="text-primary"
                        width="40"
                        height="40"
                      />
                    </a>
                  </td>
                  <td>{service.name}</td>
                  <td>{service.district}</td>
                  <td>{service.province}</td>
                  <td>{service.description}</td>

                  <td>
                    {service.service_schedules.map((schedule) => (
                      <div key={schedule.id}>
                        De {schedule.start_day} a {schedule.end_day}
                        <br></br>
                        {formatTime(schedule.start_time)} a{" "}
                        {formatTime(schedule.end_time)}
                      </div>
                    ))}
                  </td>
                  <td>
                  <div className="form-check form-switch">
                  <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck-${service.id}`}
                        style={{
                          backgroundColor: service.Activo === 1 ? "green" : "",
                        }}
                        checked={service.Activo === 1}
                        onChange={(e) =>
                          handleSwitchChange(service.id, e.target.checked)
                        }
                      />
                    </div>
                  </td>
                  <td
                    style={{ cursor: "pointer", textAlign: "center" }}
                    onClick={() => openEditServiceOfreModal(service.id)}
                  >
                    <EyesIcon className="text-primary" width="40" height="40" />
                  </td>
                  <td
                    style={{ cursor: "pointer", textAlign: "center" }}
                    onClick={() => openEditServiceModal(service.id)}
                  >
                    <PencilIcon width="30" height="30" color="green" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {userData.role === "Administrador" && (
          <table className="table table-hover table-bordered">
            <thead className="table-light">
              <tr>
                <th style={{ width: "auto" }} scope="col">
                  ID
                </th>
                <th scope="col">Foto</th>
                <th scope="col">Nombre</th>
                <th scope="col">Email</th>
                <th scope="col">DNI</th>
                <th scope="col">Rol</th>
                <th style={{ width: "50px", cursor: "pointer" }} scope="col">
                  Editar
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td style={{ textAlign:"center"}}>
                  <img
                      src={`data:image/png;base64,${user.photo}`}
                      alt={user.name}
                      width="30"
                      height="30"
                      className="rounded-circle"
                    />
                  </td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.dni}</td>
                  <td>{user.role}</td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => openEditModal(user.id)}
                  >
                    <PencilIcon width="30" height="30" color="green" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {editUser && <EditUser user={editUser} onClose={closeEditModal} />}
        {editService && (
          <EditService service={editService} onClose={closeEditModal} />
        )}
        {listServicesOf && (
          <ServiciosOfrecidos
            service={listServicesOf}
            onClose={closeEditModal}
          />
        )}
      </div>
    </div>
  );
}

export default ListService;
 */
