import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import dataDecrypt from "../utils/Encryp/data-decrypt";


Modal.setAppElement("#root");

function ModalData({ user, onClose }) {
  const [data, setData] = useState(user);
  const [token, setToken] = useState('');

  useEffect(() => {
    const encryptedToken = localStorage.getItem("access_tokenL");
    if (encryptedToken) {
      const decryptedToken = dataDecrypt(encryptedToken);
      setToken(decryptedToken);
    }
    setData(user);
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if ((name === "whatsapp" || name === "celular") && (!/^\d*$/.test(value) || value.length > 9)) {
      return;
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    axios
      .put(
        "https://apiworkpe.work-pe.com/api/auth/update/all/datos/1",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((response) => {
        console.log("Data updated:", response.data);
        onClose();
      })
      .catch((error) => console.error("Error updating data:", error));
  };

  return (
    <div className="modal-user">
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header bg-primary text-white"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h5 className="modal-title">Editar Datos de Workpe</h5>
              <span>
                <button className="btn-close btn-close-white" onClick={onClose} style={{ color: "white" }}></button>
              </span>
            </div>
            <form onSubmit={handleSave}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="whatsapp">WhatsApp</label>
                      <input
                        type="text"
                        className="form-control"
                        id="whatsapp"
                        name="whatsapp"
                        value={data.whatsapp || ""}
                        onChange={handleInputChange}
                        placeholder="Ingrese WhatsApp"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="celular">Celular</label>
                      <input
                        type="text"
                        className="form-control"
                        id="celular"
                        name="celular"
                        value={data.celular || ""}
                        onChange={handleInputChange}
                        placeholder="Ingrese Celular"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="facebook">Facebook</label>
                      <input
                        type="text"
                        className="form-control"
                        id="facebook"
                        name="facebook"
                        value={data.facebook || ""}
                        onChange={handleInputChange}
                        placeholder="Ingrese Facebook"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={data.email || ""}
                        onChange={handleInputChange}
                        placeholder="Ingrese Email"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="web">Web</label>
                      <input
                        type="text"
                        className="form-control"
                        id="web"
                        name="web"
                        value={data.web || ""}
                        onChange={handleInputChange}
                        placeholder="Ingrese Web"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Guardar Cambios
                </button>
                <button type="button" className="btn btn-secondary" onClick={onClose}>
                  Cerrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default ModalData;
