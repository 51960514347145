import React, { useState } from "react";
import { ReactComponent as CloudIcon } from "bootstrap-icons/icons/x-square-fill.svg";
import { ReactComponent as Trash3Icon } from "bootstrap-icons/icons/trash3-fill.svg";
import { OverlayTrigger, Tooltip, Button, Modal } from "react-bootstrap";
import "../../ListService/Edit-user/EditUser.css";
import createServiceOf from "../../utils/create/createServiceOf/createServiceOf";
import updateServiceOf from "../../utils/Update/UpdateServiceOf/UpdateServiceOf";
import deleteServiceOf from "../../utils/delete/deleteServiceOf/deleteServiceOf";

function ServiciosOfrecidos({ service, onClose }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceName, setServiceName] = useState("");

  const openEditServiceModal = (provided) => {
    setSelectedService(provided);
    setServiceName(provided ? provided.service : "");
    setShowEditModal(true);
  };

  const closeEditServiceModal = () => {
    setSelectedService(null);
    setServiceName("");
    setShowEditModal(false);
  };

  const handleServiceNameChange = (event) => {
    setServiceName(event.target.value);
  };

  const handleSaveChanges = async () => {
    try {
      if (selectedService) {
        // Editar servicio existente
        const updatedService = await updateServiceOf(selectedService.id, {
          service: serviceName,
        });
        console.log("Servicio editado:", updatedService);
      } else {
        // Agregar nuevo servicio
        const newService = await createServiceOf(service.id, {
          service: serviceName,
        });
        console.log("Nuevo servicio agregado:", newService);
      }

      // Cerrar el modal después de guardar cambios
      closeEditServiceModal();

      // Refrescar la página después de 1 segundo (opcional)
      setTimeout(() => {
        window.location.reload();
      }, 1000); // Cambia el tiempo según tus necesidades
    } catch (error) {
      console.error("Error al guardar cambios:", error.message);
    }
  };

  const handleDeleteService = async (providedId) => {
    try {
      if (!providedId) {
        throw new Error('El id del servicio proporcionado no es válido.');
      }
  
      // Llamar a la función para eliminar el servicio ofrecido
      const deletedService = await deleteServiceOf(service.id, {
        serviceProvidedId: providedId,
      });
      console.log("Servicio ofrecido eliminado exitosamente:", deletedService);
      window.location.reload();
      // Refrescar la lista de servicios después de eliminar (opcional)
      // Aquí puedes implementar una lógica para actualizar la lista sin recargar la página
    } catch (error) {
      console.error("Error al eliminar el servicio ofrecido:", error);
    }
  };

  return (
    <div className="modal-service">
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" ,overflowY:"auto"} }
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header bg-primary text-white"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h5 className="modal-title">Servicios ofrecidos</h5>
              <div style={{ display: "flex" }}>
                <Button
                  variant="primary"
                  onClick={() => openEditServiceModal(null)}
                  style={{ marginRight: "10px" }}
                >
                  Agregar Servicio
                </Button>
                <CloudIcon
                  width="40"
                  height="40"
                  color="white"
                  onClick={onClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="text-center mb-4">
                <img
                  src={service.imageUrl}
                  alt={service.name}
                  style={{
                    borderRadius: "50%",
                    width: "100px",
                    height: "100px",
                  }}
                />
                <h3 className="mt-3">{service.name}</h3>
                <p>{service.description}</p>
              </div>
              <div className="row" style={{flexWrap:"wrap"}}>
                {service.services_provided.map((provided) => (
                  <div className="col-md-6 mb-3" key={provided.id}>
                    <div className="card">
                      <div
                        className="card-body d-flex justify-content-between align-items-center"
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${provided.id}`}>
                              {provided.service}
                            </Tooltip>
                          }
                        >
                          <label
                            className="card-title"
                            style={{
                              fontSize: "1rem",
                              fontWeight: "bold",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              flex: 1,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            onClick={() => openEditServiceModal(provided)}
                          >
                            <span
                              style={{ marginRight: "8px", fontSize: "1.5rem" }}
                            >
                              •
                            </span>
                            {provided.service}
                          </label>
                        </OverlayTrigger>
                        <Button
                          variant="danger"
                          style={{
                            borderRadius: "20%",
                            padding: "0.5rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={() => handleDeleteService(provided.id)}
                        >
                          <Trash3Icon />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal para editar/agregar servicio */}
      <Modal show={showEditModal} onHide={closeEditServiceModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedService ? "Editar Servicio" : "Agregar Servicio"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Formulario para editar/agregar servicio */}
          <div className="form-group">
            <label htmlFor="serviceName">Nombre del Servicio</label>
            <input
              type="text"
              className="form-control"
              id="serviceName"
              placeholder="Ingrese el nombre del servicio"
              value={serviceName}
              onChange={handleServiceNameChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeEditServiceModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            {selectedService ? "Guardar Cambios" : "Agregar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ServiciosOfrecidos;
