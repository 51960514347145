import React, { useState, useEffect, useContext } from "react";
import "../sidebars/StyleSidebars.css";
import "../sidebars/Jsidebars.js";
import icono from "../../assets/logo.png";
import { ReactComponent as Speedometer2Icon } from "bootstrap-icons/icons/speedometer2.svg";
import { ReactComponent as TableIcon } from "bootstrap-icons/icons/table.svg";
import { ReactComponent as GridIcon } from "bootstrap-icons/icons/grid.svg";
import { ReactComponent as PersonIcon } from "bootstrap-icons/icons/person-circle.svg";
import { useNavigate, useLocation } from "react-router-dom";
import handleLogout from "../auth/logout/Logout";
import { fetchUserData } from "../utils/utils/ApiMeUtils.jsx";
import foto from "../../assets/descarga (3).png";
import { SidebarContext } from "./SidebarContext.jsx";

const TOKEN_KEY = "access_tokenL";

const Sidebar = ({ setContent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [userData, setUserData] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);

  const handleLogoutClick = () => {
    handleLogout(navigate);
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await fetchUserData();
        setUserData(data);
      } catch (error) {
        console.error(error);
      }
    };

    getUserData();
  }, []);

  const handleLinkClick = (path) => {
    navigate(path);
    setActiveLink(path);
   
  };


  return (
    <div className={`menu-phone ${isSidebarOpen ? "open" : "closed"}`}>
      <div>
        <div className={`content-nav-menu`}>
          <div
            className="hamburger-button"
            type="button"
            onClick={toggleSidebar}
          >
            <label className="logo-img" id="nav-responsive">
              <img
                className="img-logo-responsive-hidden"
                src={icono}
                alt=""
                style={{ width: "150px", height: "30px" }}
              />
            </label>
            <hr className="puntos" />
          </div>
          <ul className="list-group nav-phone nav mb-auto">
            <li>
              <a
                href="/dashboard"
                className={`nav-link py-3 rounded-0 ${
                  activeLink === "/dashboard" ? "activo" : ""
                }`}
                title="Dashboard"
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick("/dashboard");
                }}
              >
                <Speedometer2Icon className="icons" width="27" height="27" />{" "}
                <span className="separar-letras">Dashboard</span>
              </a>
            </li>
            <li>
              <a
                href="/Lista-de-Servicio"
                className={`nav-link py-3 rounded-0 ${
                  activeLink === "/Lista-de-Servicio" ? "activo" : ""
                }`}
                title="Lista de Servicio"
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick("/Lista-de-Servicio");
                }}
              >
                <TableIcon width="27" height="27"  className="icons"/>{" "}
                <span>Lista de Datos</span>
              </a>
            </li>
            <li>
              <a
                href="/Gestionar-Services"
                className={`nav-link py-3 rounded-0 ${
                  activeLink === "/Gestionar-Services" ? "activo" : ""
                }`}
                title="Gestionar Services"
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick("/Gestionar-Services");
                }}
              >
                <GridIcon width="27" height="27" className="icons"/>
                <span>Gestionar de Datos</span>
              </a>
            </li>
            <li>
              <a
                href="/ProfileUser"
                className={`nav-link py-3 rounded-0 ${
                  activeLink === "/ProfileUser" ? "activo" : ""
                }`}
                title="Profile User"
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick("/ProfileUser");
                }}
              >
                <PersonIcon width="27" height="27" className="icons"/> <span>Perfil</span>
              </a>
            </li>
          </ul>

          <div
            className="dropdown "
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <a
              href="#"
              className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {userData.photo ? (
                <img
                  src={`${userData.photo}`}
                  alt={userData.name}
                  width="40"
                  height="40"
                  className="img-sidebar-perfil"
                />
              ) : (
                <img
                  src={foto}
                  alt="Default"
                  width={40}
                  height={40}
                  className="rounded-circle"
                />
              )}
            </a>
            <div className="content-text-sidebar">
              <span style={{ cursor: "pointer" }}>{userData.name}</span>
              <p>{userData.role}</p>
            </div>

            <ul className="dropdown-menu text-small shadow">
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={handleLogoutClick}
                >
                  Sign out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
