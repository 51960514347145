import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pie, Bar } from "react-chartjs-2";
import { ReactComponent as DatabaseIcon } from "bootstrap-icons/icons/database-fill.svg";
import { ReactComponent as CheckIcon } from "bootstrap-icons/icons/check-circle-fill.svg";
import { ReactComponent as XcircleIcon } from "bootstrap-icons/icons/x-circle-fill.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { API_CONST_URL } from "../../config/api/api";
import dataDecrypt from "../utils/Encryp/data-decrypt";
import "./Content.css";
const TOKEN_KEY = "access_tokenL";

const CardUser = () => {
  const [userData, setUserData] = useState(null);
  const [chartDataPie, setChartDataPie] = useState(null);
  const [chartDataBar, setChartDataBar] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
          throw new Error("No se encontró el token");
        }
        const decryptedToken = dataDecrypt(token);
        const response = await axios.post(
          `${API_CONST_URL}/users/count/roles`,
          null,
          {
            headers: {
              Authorization: `Bearer ${decryptedToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.data) {
          throw new Error("Error al obtener los conteos de roles de usuario");
        }

        const {
          totalAdministrador,
          totalusuarios,
          totalProveedores,
          totalComun,
        } = response.data;

        // Configurar datos para el gráfico de pastel
        const chartDataPie = {
          labels: [
            "Administradores",
            "Usuarios",
            "Proveedores",
            "Usuarios Comunes",
          ],
          datasets: [
            {
              data: [
                totalAdministrador,
                totalusuarios,
                totalProveedores,
                totalComun,
              ],
              backgroundColor: ["purple", "blue", "green", "orange"],
              hoverBackgroundColor: ["purple", "blue", "green", "orange"],
              borderWidth: 2,
            },
          ],
        };

        setChartDataPie(chartDataPie);

        // Configurar datos para el gráfico de barras
        const chartDataBar = {
          labels: [
            "Administradores",
            "Usuarios",
            "Proveedores",
            "Usuarios Comunes",
          ],
          datasets: [
            {
              label: "Cantidad de usuarios por rol",
              data: [
                totalAdministrador,
                totalusuarios,
                totalProveedores,
                totalComun,
              ],
              backgroundColor: [
                "rgba(153, 102, 255, 0.6)",
                "rgba(54, 162, 235, 0.6)",
                "rgba(75, 192, 192, 0.6)",
                "rgba(255, 159, 64, 0.6)",
              ],
              borderColor: [
                "rgba(153, 102, 255, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
            },
          ],
        };

        setChartDataBar(chartDataBar);

        // Guardar los datos de conteo de roles en el estado
        setUserData({
          totalAdministrador,
          totalusuarios,
          totalProveedores,
          totalComun,
        });
      } catch (error) {
        console.error("Error al obtener los roles de usuario:", error.message);
      }
    };

    fetchData();
  }, []);

  const getIcon = (key) => {
    switch (key) {
      case "totalAdministrador":
        return <DatabaseIcon width={40} height={40} color="purple" />;
      case "totalusuarios":
        return <DatabaseIcon width={40} height={40} color="blue" />;
      case "totalProveedores":
        return <DatabaseIcon width={40} height={40} color="green" />;
      case "totalComun":
        return <DatabaseIcon width={40} height={40} color="orange" />;
      default:
        return null;
    }
  };

  return (
    <div className="content--card-td">
      <div className="content--cards-charts-count">
        <div className="content-cards-count-cards">
          {userData && (
            <>
              {Object.keys(userData).map((key, index) => (
                <div className="cards--count-content" key={index}>
                  <div className=" card--count h-100 text-center custom-card-bg">
                    <div className="card-body d-flex flex-column align-items-center">
                      <div className="card--cover mb-3">{getIcon(key)}</div>
                      <div className="card--title">
                        <h5 className="card-title">
                          {key
                            .replace("total", "")
                            .replace(/([A-Z])/g, " $1")
                            .trim()}
                          : {userData[key]}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="card card--grafico-content-grafico-barras-content">
          <div>
            <div
              className=" content-grafico-barras"
              style={{
                width: "100%",
                height: "400px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {chartDataBar ? (
                <Bar
                  data={chartDataBar}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          precision: 0,
                        },
                      },
                      x: {
                        display: true,
                        title: {
                          display: true,

                          color: "#333",
                          font: {
                            size: 16,
                            weight: "bold",
                          },
                        },
                      },
                    },
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (tooltipItem) =>
                            `Cantidad: ${tooltipItem.raw}`,
                        },
                      },
                      legend: {
                        display: true,
                        position: "top",
                        labels: {
                          font: {
                            size: 10,
                          },
                        },
                      },
                    },
                  }}
                />
              ) : (
                <p>Cargando gráfico...</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className=" grafico mt-4">
        <div className=" col-12 content--cardUser">
          <div className="card card--grafico">
            <div
              className="card-body"
              style={{
                width: "100%",
                height: "400px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {chartDataPie ? (
                <Pie
                  data={chartDataPie}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (tooltipItem) =>
                            `${tooltipItem.label}: ${tooltipItem.raw}`,
                        },
                      },
                      legend: {
                        display: true,
                        position: "top",
                        labels: {
                          font: {
                            size: 10,
                          },
                        },
                      },
                    },
                  }}
                />
              ) : (
                <p>Cargando gráfico...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardUser;