import axios from "axios";
import { API_CONST_URL } from "../../../../config/api/api";
import dataDecrypt from "../../Encryp/data-decrypt";

const TOKEN_KEY = "access_tokenL";

const UpdateServiceofSchedules = async (serviceId, updateData) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    throw new Error('No token found');
  }

  const decryptedToken = dataDecrypt(token);
  const updateDataSchedulesof = `${API_CONST_URL}/update/schedules/${serviceId}`;
  const headers = {
    Authorization: `Bearer ${decryptedToken}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(updateDataSchedulesof, updateData, {
      headers,
    });

    if (response.status === 200) {
      console.log("Service Provided Update successfully", response.data);
      return response.data;
    } else {
      console.error("Failed to update service provided", response.data);
      throw new Error("Failed to update service provided");
    }
  } catch (error) {
    console.error("Error updating service provided", error);
    throw new Error("Error updating service provided");
  }
};

export default UpdateServiceofSchedules;
