import React, { useState, useEffect } from "react";
import { fetchUserData } from "../utils/utils/ApiMeUtils";
import { ReactComponent as EyeIcon } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as EyeCloseIcon } from "bootstrap-icons/icons/eye-slash.svg";
import "../GestionService/GestionService.css";
import createUser from "../utils/create/createUser/createUser";
import axios from "axios";
import { API_SERVICE } from "../../config/api/api";
import createServicio from "../utils/create/createServicio/createServicio";
import dataDecrypt from "../utils/Encryp/data-decrypt";
import { dataEncrpt } from "../utils/Encryp/data-encrypt";
import { Button, Modal, Spinner } from "react-bootstrap";
import ico from "../../assets/logo.png";
import "../GestionService/GestionService.css";
/* content-form */
const TOKEN_KEY = "access_tokenL";
const USER_ROLE_KEY = `+Z7=1c]"BpK6*;q`; // Key para almacenar el rol del usuario en localStorage
function GestionService() {
  const [isDniValid, setIsDniValid] = useState(false);
  const [dni, setDni] = useState("");
  const [categorias, setCategorias] = useState([]);
  const [userData, setUserData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [offeredServices, setOfferedServices] = useState([
    {
      name_service: "",
      start_day: "",
      end_day: "",
      start_time: "",
      end_time: "",
    },
  ]);

  const [formData, setFormData] = useState({
    name: "",
    district: "",
    province: "",
    description: "",
    WhatsApp: "",
    Facebook: "",
    imageUrl: "",
    categoria_id: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
          throw new Error("Token not found");
        }
        const userDataResponse = await fetchUserData(token);
        setUserData(userDataResponse);
        localStorage.setItem(USER_ROLE_KEY, dataEncrpt(userDataResponse.role));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const storedUserRole = localStorage.getItem(USER_ROLE_KEY);
    if (storedUserRole) {
      setUserData({ role: dataDecrypt(storedUserRole) });
    } else {
      fetchData();
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleServiceChange = (index, e) => {
    const { name, value } = e.target;
    const newOfferedServices = [...offeredServices];
    newOfferedServices[index][name] = value;
    setOfferedServices(newOfferedServices);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const serviceData = {
      name: formData.name,
      district: formData.district,
      province: formData.province,
      description: formData.description,
      WhatsApp: formData.WhatsApp,
      Facebook: formData.Facebook,
      imageUrl: formData.imageUrl,
      daysOfOperationStart: offeredServices.map((service) => service.start_day),
      daysOfOperationEnd: offeredServices.map((service) => service.end_day),
      businessHoursStart:
        offeredServices.length > 0 ? offeredServices[0].start_time : "",
      businessHoursEnd:
        offeredServices.length > 0 ? offeredServices[0].end_time : "",
      servicesProvided: offeredServices.map((service) => ({
        service: service.name_service,
      })),
      categories: [formData.categoria_id],
    };
    setLoading(true);
    try {
      const createdService = await createServicio(serviceData);
      alert("Servicio registrado correctamente!");
      // Simulación de llamada a la API
      /*  console.log('Servicio creado exitosamente:', serviceData); */
      // Aquí podrías manejar redirecciones o mostrar un mensaje de éxito
      setLoading(false);
    } catch (error) {
      /* console.error('Error al crear servicio:', error); */
      // Aquí podrías manejar errores y mostrar mensajes al usuario
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [formUData, setFormUData] = useState({
    name: "",
    apellido: "",
    email: "",
    dni: "",
    usuario: "",
    fecha_nacimiento: "",
    direccion: "",
    distrito: "",
    provincia: "",
    departamento: "",
    password: "",
  });

  const handleUserInputChange = (e) => {
    const { name, value } = e.target;
    setFormUData({
      ...formUData,
      [name]: value,
    });
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createUser(formUData);
      console.log("Usuario registrado:", response);
      alert("¡Usuario registrado correctamente!");
      // Aquí podrías manejar la respuesta, como redireccionar a otra página, mostrar un mensaje de éxito, etc.
    } catch (error) {
      /*  console.error('Error al registrar usuario:', error); */
      // Aquí podrías manejar el error, como mostrar un mensaje de error al usuario
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_SERVICE}/categorias`);
        setCategorias(response.data);
      } catch (error) {
        /*  console.error('Error fetching categories:', error); */
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="my-5" style={{ width: "100%" }}>
      {loading ? (
        <div className="spinner-container">
          <img src={ico} alt="" />
          <Spinner animation="border" variant="primary" role="status"></Spinner>
        </div>
      ) : (
        <div className="table-responsive rounded bg-white p-4">
          {userData.role === "Proveedor" && currentStep === 1 && (
            <div className="container">
              <h2>Registra tu Servicio</h2>
              <form onSubmit={handleSubmit}>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">
                      Nombre del Servicio
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Ingresa el nombre del servicio"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="Facebook" className="form-label">
                      Página de Facebook
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Facebook"
                      name="Facebook"
                      value={formData.Facebook}
                      onChange={handleInputChange}
                      placeholder="Ingresa el link de Facebook"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label htmlFor="imageUrl" className="form-label">
                      Provincia
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="province"
                      name="province"
                      value={formData.province}
                      onChange={handleInputChange}
                      placeholder="Ingresa la Provincia"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="WhatsApp" className="form-label">
                      Distrito
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="district"
                      name="district"
                      value={formData.district}
                      onChange={handleInputChange}
                      placeholder="Ingresa el Distrito"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label htmlFor="WhatsApp" className="form-label">
                      Número de WhatsApp
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="WhatsApp"
                      name="WhatsApp"
                      value={formData.WhatsApp}
                      onChange={handleInputChange}
                      placeholder="Ingresa el número de WhatsApp"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="categoria_id" className="form-label">
                      Categoría
                    </label>
                    <select
                      className="form-control"
                      id="categoria_id"
                      name="categoria_id"
                      value={formData.categoria_id}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Selecciona una categoría</option>
                      {categorias.map((categoria) => (
                        <option key={categoria.id} value={categoria.id}>
                          {categoria.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <label htmlFor="description" className="form-label">
                      Descripción
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      placeholder="Ingresa descripción del servicio"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6"></div>
                  <div className="col-md-6 text-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setCurrentStep(currentStep + 1)}
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}

          {userData.role === "Proveedor" && currentStep === 2 && (
            <div className="container">
              <h2>Servicios Ofrecidos</h2>
              <form onSubmit={handleSubmit}>
                {offeredServices.map((service, index) => (
                  <div key={index} className="border rounded p-3 mb-3">
                    <div className="row" style={{display:"flex",flexWrap:"wrap"}}>
                      <div className="col-md-12">
                        <label htmlFor={`name_service-${index}`}>
                          Nombre del servicio
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id={`name_service-${index}`}
                          name="name_service"
                          value={service.name_service}
                          onChange={(e) => handleServiceChange(index, e)}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor={`start_day-${index}`}>
                          Día de Inicio
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id={`start_day-${index}`}
                          name="start_day"
                          value={service.start_day}
                          onChange={(e) => handleServiceChange(index, e)}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor={`end_day-${index}`}>Día de Fin</label>
                        <input
                          type="text"
                          className="form-control"
                          id={`end_day-${index}`}
                          name="end_day"
                          value={service.end_day}
                          onChange={(e) => handleServiceChange(index, e)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mt-12 ml-5" style={{display:"flex",flexWrap:"wrap"}}>
                      <div className="col-md-12">
                        <label htmlFor={`start_time-${index}`}>
                          Hora de Inicio
                        </label>
                        <input
                          type="time"
                          className="form-control"
                          id={`start_time-${index}`}
                          name="start_time"
                          value={service.start_time}
                          onChange={(e) => handleServiceChange(index, e)}
                          required
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor={`end_time-${index}`}>Hora de Fin</label>
                        <input
                          type="time"
                          className="form-control"
                          id={`end_time-${index}`}
                          name="end_time"
                          value={service.end_time}
                          onChange={(e) => handleServiceChange(index, e)}
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="row mt-3">
                    <div className="col-md-12 text-end">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleRemoveService(index)}
                      >
                        Eliminar Servicio
                      </button>
                    </div>
                  </div> */}
                  </div>
                ))}
                <div className="row mt-3">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setCurrentStep(currentStep - 1)}
                    >
                      Anterior
                    </button>
                  </div>
                  <div className="col-md-6 text-end">
                    {/* <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAddService}
                  >
                    Añadir Servicio
                  </button> */}
                    <button type="submit" className="btn btn-success ms-2">
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}

          {userData.role === "Administrador" && (
            <div className="container" >
              <h2>Crear Usuario</h2>
              <form onSubmit={handleUserSubmit}>
                <div className="row mt-4 " style={{display:"flex",flexWrap:"wrap"}}>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Ingrese su nombre"
                      name="name"
                      value={formUData.name}
                      onChange={handleUserInputChange}
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="apellido" className="form-label">
                      Apellido
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="apellido"
                      placeholder="Ingrese su apellido"
                      name="apellido"
                      value={formUData.apellido}
                      onChange={handleUserInputChange}
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="dni" className="form-label">
                      DNI
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="dni"
                      placeholder="Ingrese su DNI"
                      aria-describedby="dniHelp"
                      name="dni"
                      value={formUData.dni}
                      onChange={handleUserInputChange}
                      required
                    />
                    <div id="dniHelp" className="form-text">
                      El número de DNI tiene 8 dígitos
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="role" className="form-label">
                      Rol
                    </label>
                    <select
                      className="form-control"
                      id="role"
                      name="role"
                      value={formUData.role}
                      onChange={handleUserInputChange}
                      required>
                      <option value="">Selecciona rol</option>
                      <option value="1">Proveedor</option>
                      <option value="2">Administrador</option>
                      <option value="5">Cliente</option>
                    </select>
                  </div>
                  <div className="col-12 mb-3">
                    <label htmlFor="email" className="form-label">
                      Correo electrónico
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Ingrese su correo electrónico"
                      aria-describedby="emailHelp"
                      name="email"
                      value={formUData.email}
                      onChange={handleUserInputChange}
                      required
                    />
                    <div id="emailHelp" className="form-text">
                      Nunca compartiremos su correo electrónico con nadie más.
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <label htmlFor="password" className="form-label">
                      Contraseña
                    </label>
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="password"
                        placeholder="Ingrese su contraseña"
                        aria-describedby="passHelp"
                        name="password"
                        value={formUData.password}
                        onChange={handleUserInputChange}
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <EyeCloseIcon width="27" height="27" color="black" />
                        ) : (
                          <EyeIcon width="27" height="27" color="black" />
                        )}
                      </button>
                    </div>
                    <div id="passHelp" className="form-text">
                      Usa una mayúscula, números, caracteres especiales y debe
                      ser mayor a 8 caracteres.
                    </div>
                  </div>
                  <div className="col-12 mb-3 d-flex justify-content-between">
                    <button type="submit" className="btn btn-primary">
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default GestionService;
